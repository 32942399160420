"use client";
import { Faro } from "@grafana/faro-web-sdk";
import {
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
} from "@grafana/faro-react";
import { GetAnalyticsVars } from "@guberno/utils/helpers";

// eslint-disable-next-line func-style
export function FaroWeb(): Faro | undefined {
  let faro: Faro | undefined;
  GetAnalyticsVars().then((vars) => {
    if (!vars.analyticsEnabled) {
      return;
    }
    faro = initializeFaro({
      url: vars.analyticsUrl,
      apiKey: vars.analyticsApiKey,
      trackWebVitalsAttribution: true,
      sessionTracking: {
        enabled: true,
        persistent: true,
      },
      app: {
        name: "capensis",
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
        }),
        new ReactIntegration(),
      ],
    });
  });
  return faro;
}

export type { Faro } from "@grafana/faro-web-sdk";
