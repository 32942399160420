"use client";
import React, { useEffect, useRef } from "react";
import { ThemeProvider } from "@bpipartners/webui";
import { getTheme } from "@guberno/utils/theme";
import { LicenseInfo, CssBaseline } from "@bpipartners/webui";
import { SnackbarProvider } from "notistack";
import { FaroWeb, Faro } from "./Faro";
import { FaroErrorBoundary } from "@grafana/faro-react";
import { FallbackErrorComponent } from "@guberno/ui";

LicenseInfo.setLicenseKey(
  "39b20c1effd4c09bb8a75446913ddebaTz04MDMzOSxFPTE3MzM4NDI0OTEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

// eslint-disable-next-line arrow-body-style
export const ClientProviders = ({ children }: { children?: any }) => {
  const faroRef = useRef<null | Faro>(null);
  useEffect(() => {
    if (!faroRef.current && typeof window !== "undefined") {
      faroRef.current = FaroWeb();
    }
  }, []);

  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <FaroErrorBoundary
        fallback={(error, reset) =>
          FallbackErrorComponent({ error, resetErrorBoundary: reset })
        }
      >
        <SnackbarProvider
          autoHideDuration={3000}
          maxSnack={3}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {children}
        </SnackbarProvider>
      </FaroErrorBoundary>
    </ThemeProvider>
  );
};
